import isDev from "./is-dev";

const isTestDeploy = process.env.REACT_APP_TEST_DEPLOY === "1";

const devURL = "https://swiss.westeurope.cloudapp.azure.com/test";

const prodURL = "https://swiss.westeurope.cloudapp.azure.com/api-prod";

const baseURL = isDev || isTestDeploy ? devURL : prodURL;

export default baseURL;
