import baseURL from "utils/base-url";
import styles from "./dashboard.module.scss";

const Dashboard = () => {
  return (
    <iframe
      className={styles.iframe}
      title="dashboard"
      src={baseURL + "/dashboard/"}
    />
  );
};

export default Dashboard;
