import React from "react";

import Input from "components/input";
import Button from "components/button";
import { useSelector } from "store/hooks";
import request from "request";
import styles from "./create-modal.module.scss";
import transcript from "utils/transcript";

const CreditModal = ({ onClose, onCreate }: { onClose: () => void; onCreate: () => void }) => {
  const user = useSelector((state) => state.user);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setLogin] = React.useState("");
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [role, setRole] = React.useState("Кредитный эксперт");
  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name: "firstName" | "lastName" | "email" | "password1" | "password2" | "role";
    value: string;
  } | null>(null);

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (firstName.trim() === "")
      return setValidError({
        name: "firstName",
        value: "Введите имя пользователя",
      });
    if (lastName.trim() === "")
      return setValidError({
        name: "lastName",
        value: "Введите фамилию пользователя",
      });
    if (email.trim().length < 5)
      return setValidError({
        name: "email",
        value: "Минимальная длина логин 5 символов",
      });
    if (password1.trim().length < 6)
      return setValidError({
        name: "password1",
        value: "Минимальная длина пароля 6 символов",
      });
    if (password1.trim() !== password2.trim())
      return setValidError({
        name: "password2",
        value: "Пароли не совпадают",
      });
    if (role === "")
      return setValidError({
        name: "role",
        value: "Роль обязателена",
      });
    setValidError(null);
    setPending(true);
    request
      .post("/admin/users", {
        first_name: firstName,
        last_name: lastName,
        email: email.toLowerCase(),
        password1,
        password2,
        role: transcript.userRole.getKeyFromValue(role),
      })
      .then(() => onCreate())
      .finally(() => setPending(false));
  };
  
  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onSubmit={handleCreate}
        onMouseDown={(event) => event.stopPropagation()}
      >
        <div className={styles.title}>Новый пользователь</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="Имя"
              type="string"
              value={firstName}
              onInput={(value) => setFirstName(value)}
            />
            {validError?.name === "firstName" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Фамилия"
              type="string"
              value={lastName}
              onInput={(value) => setLastName(value)}
            />
            {validError?.name === "lastName" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Эл. почта"
              type="string"
              value={email}
              onInput={(value) => setLogin(value)}
            />
            {validError?.name === "email" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Пароль"
              type="password"
              value={password1}
              onInput={(value) => setPassword1(value)}
            />
            {validError?.name === "password1" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Подтвердить пароль"
              type="password"
              value={password2}
              onInput={(value) => setPassword2(value)}
            />
            {validError?.name === "password2" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          {user.role === "root" && (
            <div className={styles.input}>
              <Input
                id="role"
                title="Роль"
                type="select"
                value={role}
                onSelect={(option) => setRole(option)}
                options={["Кредитный эксперт", "Администратор", "Root"]}
              />
              {validError?.name === "role" && (
                <div className={styles.inputError}>{validError.value}</div>
              )}
            </div>
          )}
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Отмена
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Создать
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreditModal;
