import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { toast } from "react-toastify";

import baseURL from "utils/base-url";

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token: string | null = localStorage.getItem("token") || null;

  let headers = config.headers ?? {};

  if (token) headers.Authorization = `Bearer ${token}`;

  const newConfig: InternalAxiosRequestConfig = {
    ...config,
    headers,
  };

  return newConfig;
});

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as any;
    if (error.code === "ERR_NETWORK") {
      toast.error("Ошибка на стороне сервера");
      return { code: 500 };
    }
    // else if (error.response?.status === 422) {
    // (error.response.data as any)?.detail.forEach((log: any) => {
    //   toast.error(log.msg);
    // });
    // }
    else if (error.response?.status === 401 && !originalRequest._retry) {
      const refresh_token = localStorage.getItem("refresh_token");
      if (refresh_token) {
        await fetch(baseURL + "/refresh_tokens", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + refresh_token,
          },
        })
          .then((res) => {
            if (res.status !== 200) {
              localStorage.removeItem("refresh_token");
              localStorage.removeItem("token");
              window.location.replace("/login");
              return null;
            }
            return res.json();
          })
          .then((res) => {
            if (res === null) return;
            if (res.token) localStorage.setItem("token", res.token);
            if (res.refresh_token)
              localStorage.setItem("refresh_token", res.refresh_token);
            originalRequest._retry = true;
          });
        return instance(originalRequest);
      } else {
        localStorage.removeItem("token");
        window.location.replace("/login");
      }
    } else if (error.response?.status === 403) {
      localStorage.removeItem("token");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

export const request = instance;

export default request;
