import React from "react";
import classNames from "classnames";
import styles from "./toggle-box.module.scss";

const ToggleBox = ({
  active: propActive,
  onToggle,
}: {
  active?: boolean;
  onToggle: () => void;
}) => {
  const [active, setActive] = React.useState(propActive);
  const mouseXPosition = React.useRef<number>();
  const block = React.useRef<boolean>();

  const handleToggle = () => !block.current && onToggle();

  const handleMouseMove = React.useCallback((e: MouseEvent) => {
    if (!mouseXPosition.current) return;
    block.current = true;
    if (e.clientX >= mouseXPosition.current + 5) {
      setActive(true);
    } else if (e.clientX <= mouseXPosition.current - 5) {
      setActive(false);
    } else block.current = false;
  }, []);

  React.useEffect(() => {
    setActive(propActive);
  }, [propActive]);

  React.useEffect(() => {
    const handleMouseUp = () => {
      mouseXPosition.current = undefined;
      document.body.style.cursor = "default";
    };
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  return (
    <button
      type="button"
      onClick={handleToggle}
      className={classNames(styles.toggleBox, active && styles.active)}
      onMouseDown={(e) => {
        document.body.style.cursor = "pointer";
        block.current = false;
        mouseXPosition.current = e.clientX;
        window.addEventListener("mousemove", handleMouseMove);
      }}
    />
  );
};

export default ToggleBox;
