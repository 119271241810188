import React from "react";
import { AxiosError } from "axios";
import { Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import Button from "components/button";
import Loading from "components/loading";
import ResultBox from "components/result-box";
import transcript from "utils/transcript";
import global from "global";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import styles from "./ocr.module.scss";
import { bankType } from "utils/constants";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: "medium"
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: "bold"
    }
  ]
});

export const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "3px 10px",
    fontWeight: "medium"
  },
  section: {
    marginBottom: "5px",
    marginTop: "5px"
  },
  heading: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "bold"
  },
  heading2: {
    fontSize: "18px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px"
  },
  text: {
    fontSize: "12px",
    marginBottom: "10px"
  },
  text2: {
    fontSize: "12px",
    marginTop: "10px"
  },
  table: {
    backgroundColor: "#fff"
  },
  tr: {
    display: "flex",
    flexDirection: "row"
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "12px"
  },
  th: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "12px",
    fontWeight: "bold"
  },
  result: {
    fontSize: "16px",
    fontWeight: "bold"
  },
  bottom: {
    margin: "10px 0 0 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  bottom2: {
    marginTop: "40px"
  }
});

const OCR = () => {
  const [ocrPending, setOcrPending] = React.useState(false);
  const [fileKASPI, setFileKASPI] = React.useState<File | null>();
  const [filePKB, setFilePKB] = React.useState<File | null>();

 

  const dispatch = useDispatch();
  const contextApplication = useSelector((state) => state.application);

  const { id } = useParams();

  const handleRunOCR = async () => {
    setOcrPending(true);
    const fd = new FormData();
    if (fileKASPI) fd.append("kaspi", fileKASPI);
    if (filePKB) fd.append("pkb", filePKB);
    if (filePKB && !fileKASPI) fd.append("recognize", "pkb");
    if (fileKASPI && !filePKB) fd.append("recognize", "kaspi");
    if (filePKB && fileKASPI) fd.append("recognize", "both");
    if (bankType) fd.append("kaspi_type", transcript.bankType(bankType) ?? "");
    request({
      url: `/applications/${id}/ocr/statements`,
      method: "POST",
      data: fd,
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then((res) => res.data)
      .catch((e: AxiosError) => {
        console.log(e);
      })
      .finally(async () => {
        if (filePKB && fileKASPI && contextApplication.data?.scoring_log)
          await request.post(`applications/${contextApplication.data.id}/cdn`);
        dispatch.application.FETCH_APPLICATION_BY_ID(id);
      });
  };

  const handleFileChangeKASPI = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) setFileKASPI(event.target.files[0]);
  };

  const handleFileChangePKB = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) setFilePKB(event.target.files[0]);
  };

  if (!contextApplication.data) return <></>;

  const { first_name, last_name, email } = contextApplication.data.created_by ?? {};
  const {
    mean,
    id: kaspiId,
    created_at: kaspiCreatedAt,
    updated_at: kaspiUpdatedAt,
    starting_date: kaspiStartingDate,
    end_date: kaspiEndDate,
    is_approved: kaspiApproved,
    ...kaspi
  } = contextApplication.data.ocr_log ?? {};
  const {
    date_made,
    id: pkbId,
    created_at: pkbCreatedAt,
    updated_at: pkbUpdatedAt,
    is_approved: pkbApproved,
    ...pkb
  } = contextApplication.data.pkb_ocr_log ?? {};

  return (
    <div className={styles.ocr}>
      {(contextApplication.data?.ocr_log || contextApplication.data?.pkb_ocr_log) && (
        <div className={styles.fields}>
          {contextApplication.data?.ocr_log && (
            <div className={styles.creditDataBox}>
              <div className={styles.title}>
                {transcript.bankType(contextApplication.data?.ocr_log.statement_type)}
              </div>
              <div className={styles.description}>
                <div>
                  OCR проведён экспертом: {[first_name, last_name, "(" + email + ")"].join(" ")}
                </div>
                <div>
                  Дата проведения OCR:{" "}
                  {kaspiCreatedAt || pkbCreatedAt
                    ? new Date(kaspiCreatedAt || pkbCreatedAt).toLocaleString()
                    : "-"}
                </div>
              </div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.key}>Дата начала</div>
                  <div className={styles.value}>{new Date(kaspiStartingDate).toLocaleString()}</div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Дата окончания</div>
                  <div className={styles.value}>{new Date(kaspiEndDate).toLocaleString()}</div>
                </div>
                {/* Период выписки */}
                <div className={styles.item}>
                  <div className={styles.key}>Период выписки</div>
                  <div className={styles.value}>{kaspi.date_range}</div>
                </div>
                {/* Общая сумма за весь период */}
                <div className={styles.item}>
                  <div className={styles.key}>Общая сумма за весь период</div>
                  <div className={styles.value}>{kaspi.summa}</div>
                </div>
                {/* Общая сумма за последние 6 месяцев */}
                <div className={styles.item}>
                  <div className={styles.key}>Общая сумма за последние 6 месяцев</div>
                  <div className={styles.value}>{kaspi.total_6_months}</div>
                </div>
                {/* Средняя сумма за последние 6 месяцев */}
                <div className={styles.item}>
                  <div className={styles.key}>Средняя сумма за последние 6 месяцев</div>
                  <div className={styles.value}>{kaspi.average_6_months}</div>
                </div>
                {/* Сумма депозита КАСПИ (пополнение) */}
                <div className={styles.item}>
                  <div className={styles.key}>Сумма депозита (пополнение)</div>
                  <div className={styles.value}>{kaspi.from_kaspi_deposit}</div>
                </div>
                {/* Сумма депозита КАСПИ (снятие) */}
                <div className={styles.item}>
                  <div className={styles.key}>Сумма депозита (снятие)</div>
                  <div className={styles.value}>{kaspi.to_kaspi_deposit}</div>
                </div>
                {/* Макс.сумма пополнения */}
                <div className={styles.item}>
                  <div className={styles.key}>Макс.сумма пополнения</div>
                  <div className={styles.value}>{kaspi.max_single_popolnenie}</div>
                </div>
                {/* От кого макс. Пополнение */}
                <div className={styles.item}>
                  <div className={styles.key}>От кого макс. Пополнение</div>
                  <div className={styles.value}>{kaspi.source_max_single_popolnenie}</div>
                </div>
                {/* Процент от общего пополнения */}
                <div className={styles.item}>
                  <div className={styles.key}>Процент от общего пополнения</div>
                  <div className={styles.value}>{kaspi.percent_of_contiribution}</div>
                </div>
                {/* Общее количество контрагентов */}
                <div className={styles.item}>
                  <div className={styles.key}>Общее количество контрагентов</div>
                  <div className={styles.value}>{kaspi.kolichestvo_contragenta}</div>
                </div>
                {/* От кого получено */}
                <div className={styles.item}>
                  <div className={styles.key}>От кого получено</div>
                  <div className={styles.value}>{kaspi.highest_contributor_source}</div>
                </div>
                {/* Макс.сумм с одного контрагента */}
                <div className={styles.item}>
                  <div className={styles.key}>Макс.сумм с одного контрагента</div>
                  <div className={styles.value}>{kaspi.highest_contributor_amount}</div>
                </div>
                {/* Общее количество букмекерских действий за 6 месяцев */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    Общее количество букмекерских действий за 6 месяцев
                  </div>
                  <div className={styles.value}>{kaspi.bukmeker_amount}</div>
                </div>
                {/* Сумма букмекерских действий за 6 месяцев */}
                <div className={styles.item}>
                  <div className={styles.key}>Сумма букмекерских действий за 6 месяцев</div>
                  <div className={styles.value}>{kaspi.bukmeker_transfer}</div>
                </div>
              </div>
            </div>
          )}
          {contextApplication.data?.pkb_ocr_log && (
            <div className={styles.creditDataBox}>
              <div className={styles.title}>ПКБ</div>
              <div className={styles.list}>
                {/* 1. Ф.И.О клиента */}
                <div className={styles.item}>
                  <div className={styles.key}>{"Ф.И.О клиента"}</div>
                  <div className={styles.value}>{pkb.full_name}</div>
                </div>
                {/* 2. Количество активных кредитов (в роли заёмщика) > 6 */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Количество активных кредитов (в роли заёмщика) > 6"}
                  </div>
                  <div className={styles.value}>{pkb.active_credits}</div>
                </div>
                {/* 3. Сумма активных кредитов (в роли заёмщика) */}
                <div className={styles.item}>
                  <div className={styles.key}>{"Сумма активных кредитов (в роли заёмщика)"}</div>
                  <div className={styles.value}>{pkb.amount_of_borrower_active_loans}</div>
                </div>
                {/* 4. Минимальный платёж (в роли заёмщика) */}
                <div className={styles.item}>
                  <div className={styles.key}>{"Минимальный платёж (в роли заёмщика)"}</div>
                  <div className={styles.value}>{pkb.minimal_plat}</div>
                </div>
                {/* 5. Полученные активные кредиты за последние 3 месяца */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Полученные активные кредиты за последние 3 месяца"}
                  </div>
                  <div className={styles.value}>{pkb.loans_last_3_motnhs}</div>
                </div>
                {/* 6. Сумма активных кредитов за последние 3 месяца */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Сумма активных кредитов за последние 3 месяца"}
                  </div>
                  <div className={styles.value}>{pkb.summa_few_3_months}</div>
                </div>
                {/* 7. Количество запросов ПКБ за 30 дней */}
                <div className={styles.item}>
                  <div className={styles.key}>{"Количество запросов ПКБ за 30 дней"}</div>
                  <div className={styles.value}>{pkb.num_of_pkb_requests_last_30days}</div>
                </div>
                {/* 8. Текущее количество кредитов на просрочке */}
                {/* <div className={styles.item}>
                  <div className={styles.key}>
                    {"Текущее количество кредитов на просрочке"}
                  </div>
                  <div className={styles.value}>
                    {pkb.current_credit_overdue}
                  </div>
                </div> */}
                {/* 9. Количество допущенных просрочек */}
                {/* <div className={styles.item}>
                  <div className={styles.key}>{"Количество допущенных просрочек"}</div>
                  <div className={styles.value}>{pkb.amount_overdue_loans}</div>
                </div> */}
                {/* 10. Макс. количество допущенной просрочки */}
                {/* <div className={styles.item}>
                  <div className={styles.key}>
                    {"Макс. количество допущенной просрочки"}
                  </div>
                  <div className={styles.value}>{pkb.max_overdue}</div>
                </div> */}
                {/* 11. Текущие кредиты на просрочке: длительность просрочки >5 дней */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Текущие кредиты на просрочке: длительность просрочки >5 дней"}
                  </div>
                  <div className={styles.value}>{pkb.active_delays_5days_1loan}</div>
                </div>
                {/* 12. Допущенные просроченные кредиты: длительность просрочки >5 дней, макс кол-во 10 раз */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    {
                      "Допущенные просроченные кредиты: длительность просрочки >5 дней, макс кол-во 10 раз"
                    }
                  </div>
                  <div className={styles.value}>
                    {pkb.dpd_more_then_5days_last_12month ? "Есть" : "Нет"}
                  </div>
                </div>
                {/* 13. Макс. допущенные просроченные кредиты: длительность просрочки >=30 дней за 18 месяцев */}
                {/* <div className={styles.item}>
                  <div className={styles.key}>
                    {
                      "Макс. допущенные просроченные кредиты: длительность просрочки >=30 дней за 18 месяцев"
                    }
                  </div>
                  <div className={styles.value}>{pkb.max_delays_30days_18months}</div>
                </div> */}
                {/* 14. Допущенные просроченные кредиты: длительность просрочки >=30 дней за 18 месяцев */}
                {/* <div className={styles.item}>
                  <div className={styles.key}>
                    {
                      "Допущенные просроченные кредиты: длительность просрочки >=30 дней за 18 месяцев"
                    }
                  </div>
                  <div className={styles.value}>{pkb.total_delays_30days_18months}</div>
                </div> */}
                {/* 15. Количество ПДЛ займов */}
                <div className={styles.item}>
                  <div className={styles.key}>{"Количество ПДЛ займов"}</div>
                  <div className={styles.value}>{pkb.active_pdl_loans}</div>
                </div>
                {/* 16. Допущенные просроченные кредиты: Суммарное кол-во просроченных > 60 дней */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Допущенные просроченные кредиты: Суммарное кол-во просроченных > 60 дней"}
                  </div>
                  <div className={styles.value}>
                    {pkb.cumulative_dpd_last_12month ? "Есть" : "Нет"}
                  </div>
                </div>
                {/* 17. Допущенные просроченные кредиты: длительность просрочки >20 дней */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Допущенные просроченные кредиты: длительность просрочки >20 дней"}
                  </div>
                  <div className={styles.value}>{pkb.dpd_last_12month ? "Есть" : "Нет"}</div>
                </div>
                {/* 18. Количество активных кредитов (в роли Гарант/Созаемщик/Поручитель/Залогодатель) */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    {
                      "Количество активных кредитов (в роли Гарант/Созаемщик/Поручитель/Залогодатель)"
                    }
                  </div>
                  <div className={styles.value}>{pkb.other_active_loans}</div>
                </div>
                {/* 19. Сумма активных кредитов (в роли Гарант/Созаемщик/Поручитель/Залогодатель) */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Сумма активных кредитов (в роли Гарант/Созаемщик/Поручитель/Залогодатель)"}
                  </div>
                  <div className={styles.value}>{pkb.amount_of_other_active_loans}</div>
                </div>
                {/* 20. Минимальный платёж (в роли Гарант/Созаемщик/Поручитель/Залогодатель) */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Минимальный платёж (в роли Гарант/Созаемщик/Поручитель/Залогодатель)"}
                  </div>
                  <div className={styles.value}>{pkb.other_loans_minimum_payment}</div>
                </div>
                {/* 21. Текущие кредиты на просрочке: длительность просрочки >60 дней (в роли Гарант/Созаемщик/Поручитель/Залогодатель) */}
                <div className={styles.item}>
                  <div className={styles.key}>
                    {
                      "Текущие кредиты на просрочке: длительность просрочки >60 дней (в роли Гарант/Созаемщик/Поручитель/Залогодатель)"
                    }
                  </div>
                  <div className={styles.value}>{pkb.other_loans_dpd_last_12month}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!contextApplication.data?.ocr_log &&
        !contextApplication.data?.pkb_ocr_log &&
        !ocrPending && (
          <div className={styles.inputBox}>
            {/* <div className={styles.textInput}>
              <div className={styles.textInputTitle}>Тип банка:</div>
              <Input
                type="select"
                value={bankType}
                onSelect={(option) => setBankType(option)}
                options={[
                  "КАСПИ для физ. лица",
                  "БЦК для физ. лица",
                  "Жусан для ИП",
                  "Жусан для физ. лица",
                  "Жусан для ТОО",
                  "Халык для физ. лица",
                  "Халык для юр. лица"
                ]}
              />
            </div> */}
            <label className={styles.fileInput}>
              <div className={styles.fileInputTitle}>КАСПИ для физ. лица:</div>
              {fileKASPI?.name ?? (
                <div className={styles.fileInputDescription}>
                  Загрузить <span className={styles.bold}>.pdf</span> файл
                </div>
              )}
              <input accept="application/pdf" type="file" onChange={handleFileChangeKASPI} />
            </label>
            <label className={styles.fileInput}>
              <div className={styles.fileInputTitle}>ПКБ:</div>
              {filePKB?.name ?? (
                <div className={styles.fileInputDescription}>
                  Загрузить <span className={styles.bold}>.pdf</span> файл
                </div>
              )}
              <input accept="application/pdf" type="file" onChange={handleFileChangePKB} />
            </label>
            <div className={styles.footerBox}>
              <div className={styles.buttonGroup}>
                <Button onClick={handleRunOCR}>Запустить OCR</Button>
              </div>
            </div>
          </div>
        )}
      <div className={styles.result}>
        {ocrPending && (
          <div className={styles.resultBox}>
            <Loading size={50} />
          </div>
        )}
        <ResultBox />
      </div>
    </div>
  );
};

export const OCRPdf = ({
  contextApplication
}: {
  contextApplication: global.contextApplication;
}) => {
  if (!contextApplication.data) return <></>;

  const { first_name, last_name, email } = contextApplication.data.created_by ?? {};
  const {
    mean,
    id: kaspiId,
    created_at: kaspiCreatedAt,
    updated_at: kaspiUpdatedAt,
    starting_date: kaspiStartingDate,
    end_date: kaspiEndDate,
    is_approved: kaspiApproved,
    ...kaspi
  } = contextApplication.data.ocr_log ?? {};
  const {
    date_made,
    id: pkbId,
    created_at: pkbCreatedAt,
    updated_at: pkbUpdatedAt,
    ...pkb
  } = contextApplication.data.pkb_ocr_log ?? {};

  const pkbApproved = contextApplication.data?.pkb_ocr_log?.is_approved;

  return (
    <>
      {contextApplication.data.ocr_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>Результат OCR</Text>
          </View>
          <View style={pdfStyles.text}>
            <Text>
              OCR проведён экспертом: {[first_name, last_name, "(" + email + ")"].join(" ")}
            </Text>
            <Text>
              Дата проведения OCR:{" "}
              {kaspiCreatedAt || pkbCreatedAt
                ? new Date(kaspiCreatedAt || pkbCreatedAt).toLocaleString()
                : "-"}
            </Text>
          </View>
          <View style={pdfStyles.heading2}>
            <Text>{transcript.bankType(contextApplication.data?.ocr_log.statement_type)}</Text>
          </View>
          <View style={pdfStyles.section}>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Дата начала</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{new Date(kaspiStartingDate).toLocaleString()}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Дата окончания</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{new Date(kaspiEndDate).toLocaleString()}</Text>
                </View>
              </View>
              {/* 3. Период выписки */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Период выписки</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.date_range}</Text>
                </View>
              </View>
              {/* 4. Общая сумма за весь период */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Общая сумма за весь период</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.summa}</Text>
                </View>
              </View>
              {/* 5. Общая сумма за последние 6 месяцев */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Общая сумма за последние 6 месяцев</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.total_6_months}</Text>
                </View>
              </View>
              {/* 6. Средняя сумма за последние 6 месяцев */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Средняя сумма за последние 6 месяцев</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.average_6_months}</Text>
                </View>
              </View>
              {/* 7. Сумма депозита КАСПИ (пополнение) */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Сумма депозита (пополнение)</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.from_kaspi_deposit}</Text>
                </View>
              </View>
              {/* 8. Сумма депозита КАСПИ (снятие) */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Сумма депозита (снятие)</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.to_kaspi_deposit}</Text>
                </View>
              </View>
              {/* 9. Макс.сумма пополнения */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Макс.сумма пополнения</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.max_single_popolnenie}</Text>
                </View>
              </View>
              {/* 10. От кого макс. Пополнение */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>От кого макс. Пополнение</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.source_max_single_popolnenie}</Text>
                </View>
              </View>
              {/* 11. Процент от общего пополнения */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Процент от общего пополнения</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.percent_of_contiribution}</Text>
                </View>
              </View>
              {/* 12. Общее количество контрагентов */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Общее количество контрагентов</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.kolichestvo_contragenta}</Text>
                </View>
              </View>
              {/* 13. От кого получено */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>От кого получено</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.highest_contributor_source}</Text>
                </View>
              </View>
              {/* 14. Макс.сумм с одного контрагента */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Макс.сумм с одного контрагента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.highest_contributor_amount}</Text>
                </View>
              </View>
              {/* 15. Общее количество букмекерских действий за 6 месяцев */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Общее количество букмекерских действий за 6 месяцев</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.bukmeker_amount}</Text>
                </View>
              </View>
              {/* 16. Сумма букмекерских действий за 6 месяцев  за 6 месяцев */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Сумма букмекерских действий за 6 месяцев</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{kaspi.bukmeker_transfer}</Text>
                </View>
              </View>
            </View>
            {kaspiApproved !== null && (
              <View style={pdfStyles.bottom}>
                <Text style={pdfStyles.result}>
                  Результат : {kaspiApproved ? "Одобрено" : "Отказано"}
                </Text>
              </View>
            )}
          </View>
        </Page>
      )}
      {contextApplication.data.pkb_ocr_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading2}>
            <Text>ПКБ</Text>
          </View>
          <View style={pdfStyles.section}>
            <View style={pdfStyles.table}>
              {/* 1. Ф.И.О клиента */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Ф.И.О клиента"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.full_name}</Text>
                </View>
              </View>
              {/* 2. Количество активных кредитов (в роли заёмщика) > 6 */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Количество активных кредитов (в роли заёмщика) > 6"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.active_credits}</Text>
                </View>
              </View>
              {/* 3. Сумма активных кредитов (в роли заёмщика) */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Сумма активных кредитов (в роли заёмщика)"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.amount_of_borrower_active_loans}</Text>
                </View>
              </View>
              {/* 4. Минимальный платёж (в роли заёмщика) */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Минимальный платёж (в роли заёмщика)"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.minimal_plat}</Text>
                </View>
              </View>
              {/* 5. Полученные активные кредиты за последние 3 месяца */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Полученные активные кредиты за последние 3 месяца"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.loans_last_3_motnhs}</Text>
                </View>
              </View>
              {/* 6. Сумма активных кредитов за последние 3 месяца */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Сумма активных кредитов за последние 3 месяца"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.summa_few_3_months}</Text>
                </View>
              </View>
              {/* 7. Количество запросов ПКБ за 30 дней */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Количество запросов ПКБ за 30 дней"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.num_of_pkb_requests_last_30days}</Text>
                </View>
              </View>
              {/* 8. Текущее количество кредитов на просрочке */}
              {/* <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Текущее количество кредитов на просрочке"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.current_credit_overdue}</Text>
                </View>
              </View> */}
              {/* 9. Количество допущенных просрочек */}
              {/* <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Количество допущенных просрочек"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.amount_overdue_loans}</Text>
                </View>
              </View> */}
              {/* 10. Макс. количество допущенной просрочки */}
              {/* <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Макс. количество допущенной просрочки"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.max_overdue}</Text>
                </View>
              </View> */}
              {/* 11. Текущие кредиты на просрочке: длительность просрочки >5 дней */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Текущие кредиты на просрочке: длительность просрочки >5 дней"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.active_delays_5days_1loan}</Text>
                </View>
              </View>
              {/* 12. Допущенные просроченные кредиты: длительность просрочки >5 дней, макс кол-во 10 раз */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>
                    {
                      "Допущенные просроченные кредиты: длительность просрочки >5 дней, макс кол-во 10 раз"
                    }
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.dpd_more_then_5days_last_12month ? "Есть" : "Нет"}</Text>
                </View>
              </View>
              {/* 13. Макс. допущенные просроченные кредиты: длительность просрочки >=30 дней за 18 месяцев */}
              {/* <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>
                    {
                      "Макс. допущенные просроченные кредиты: длительность просрочки >=30 дней за 18 месяцев"
                    }
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.max_delays_30days_18months}</Text>
                </View>
              </View> */}
              {/* 14. Допущенные просроченные кредиты: длительность просрочки >=30 дней за 18 месяцев */}
              {/* <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>
                    {
                      "Допущенные просроченные кредиты: длительность просрочки >=30 дней за 18 месяцев"
                    }
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.total_delays_30days_18months}</Text>
                </View>
              </View> */}
              {/* 15. Количество ПДЛ займов */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Количество ПДЛ займов"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.active_pdl_loans}</Text>
                </View>
              </View>
              {/* 16. Допущенные просроченные кредиты: Суммарное кол-во просроченных > 60 дней */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>
                    {"Допущенные просроченные кредиты: Суммарное кол-во просроченных > 60 дней"}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.cumulative_dpd_last_12month ? "Есть" : "Нет"}</Text>
                </View>
              </View>
              {/* 17. Допущенные просроченные кредиты: длительность просрочки >20 дней */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Допущенные просроченные кредиты: длительность просрочки >20 дней"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.dpd_last_12month ? "Есть" : "Нет"}</Text>
                </View>
              </View>
              {/* 18. Количество активных кредитов (в роли Гарант/Созаемщик/Поручитель/Залогодатель) */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>
                    {
                      "Количество активных кредитов (в роли Гарант/Созаемщик/Поручитель/Залогодатель)"
                    }
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.other_active_loans}</Text>
                </View>
              </View>
              {/* 19. Сумма активных кредитов (в роли Гарант/Созаемщик/Поручитель/Залогодатель) */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>
                    {"Сумма активных кредитов (в роли Гарант/Созаемщик/Поручитель/Залогодатель)"}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.amount_of_other_active_loans}</Text>
                </View>
              </View>
              {/* 20. Минимальный платёж (в роли Гарант/Созаемщик/Поручитель/Залогодатель) */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>
                    {"Минимальный платёж (в роли Гарант/Созаемщик/Поручитель/Залогодатель)"}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.other_loans_minimum_payment}</Text>
                </View>
              </View>
              {/* 21. Текущие кредиты на просрочке: длительность просрочки >60 дней (в роли Гарант/Созаемщик/Поручитель/Залогодатель) */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>
                    {
                      "Текущие кредиты на просрочке: длительность просрочки >60 дней (в роли Гарант/Созаемщик/Поручитель/Залогодатель)"
                    }
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{pkb.other_loans_dpd_last_12month}</Text>
                </View>
              </View>
            </View>
            {pkbApproved !== null && (
              <View style={pdfStyles.bottom}>
                <Text style={pdfStyles.result}>
                  Результат ПКБ: {pkbApproved ? "Одобрено" : "Отказано"}
                </Text>
              </View>
            )}
          </View>
        </Page>
      )}
    </>
  );
};

export default OCR;
