const transcript = {
  occupation_area: (key: string) =>
    ({
      banking_finance: "Банки и финансы",
      education: "Образование",
      jkh: "ЖКХ",
      medicine: "Медицина",
      agriculture: "Сельское хозяйство",
      building: "Строительство",
      pensioner: "Пенсионер",
      police: "Полиция",
      production: "Производство",
      trade: "Торговля",
      transport_commun: "Транспорт",
      gov_employee: "Госслужащий",
      services: "Услуги",
      other: "Другое",
      "Банки и финансы": "banking_finance",
      Образование: "education",
      ЖКХ: "jkh",
      Медицина: "medicine",
      "Сельское хозяйство": "agriculture",
      Строительство: "building",
      Пенсионер: "pensioner",
      Полиция: "police",
      Производство: "production",
      Торговля: "trade",
      Транспорт: "transport_commun",
      Госслужащий: "gov_employee",
      Услуги: "services",
      Другое: "other",
    }[key]),
  occupation: (key: string) =>
    ({
      worker: "Рабочий",
      pensionstud: "Пенсионер",
      highspec: "Специалист",
      boss: "Руководитель",
      entrepreneur: "Предприниматель",
      Рабочий: "worker",
      Пенсионер: "pensionstud",
      Специалист: "highspec",
      Руководитель: "boss",
      Предприниматель: "entrepreneur",
    }[key]),
  bankType: (key: string) =>
    ({
      "КАСПИ для физ. лица": "kaspi",
      "БЦК для физ. лица": "bck",
      "Жусан для ИП": "jusan_ind_ent",
      "Жусан для физ. лица": "jusan_new_1",
      "Жусан для ТОО": "jusan_new_2",
      "Халык для юр. лица": "halyk",
      "Халык для физ. лица": "halyk_fiz",
      kaspi: "КАСПИ для физ. лица",
      bck: "БЦК для физ. лица",
      jusan_ind_ent: "Жусан для ИП",
      jusan_new_1: "Жусан для физ. лица",
      jusan_new_2: "Жусан для ТОО",
      halyk: "Халык для юр. лица",
      halyk_fiz: "Халык для физ. лица",
    }[key]),
  collateral: (key: string) =>
    ({
      auto: "Автомобиль",
      real_estate: "Недвижимость",
      none: "Без залога",
      Автомобиль: "auto",
      Недвижимость: "real_estate",
      "Без залога": "none",
    }[key]),
  paymentMethod: (key: string) =>
    ({
      annuity: "Аннуитетный",
      interest: "Процентный",
      Аннуитетный: "annuity",
      Процентный: "interest",
    }[key]),
  work_experience: (key: string) =>
    ({
      noexp: "Без опыта",
      less_than_one: "Менее 1 года",
      one_to_three: "От 1 до 3 лет",
      three_to_five: "От 3 до 5 лет",
      more_than_five: "Более 5 лет",
      "Без опыта": "noexp",
      "Менее 1 года": "less_than_one",
      "От 1 до 3 лет": "one_to_three",
      "От 3 до 5 лет": "three_to_five",
      "Более 5 лет": "more_than_five",
    }[key]),
  branch: (key: string) =>
    ({
      aktau: "Актау",
      aktobe: "Актобе",
      almaty: "Алматы",
      astana: "Астана",
      atirau: "Атырау",
      esik: "Есик",
      jetisu: "Жетысу",
      karaganda: "Караганда",
      kaskelen: "Каскелен",
      kizilorda: "Кызылорда",
      kostanay: "Костанай",
      nurmakova: "Нурмакова",
      pavlodar: "Павлодар",
      sariagash: "Сарыагаш",
      semey: "Семей",
      shimkent: "Шымкент",
      taldikorgan: "Талдыкорган",
      talgar: "Талгар",
      taraz: "Тараз",
      turkestan: "Туркестан",
      uralsk: "Уральск",
      ustkamenogorsk: "Усть-Каменогорск",
      /////////////////
      Актау: "aktau",
      Актобе: "aktobe",
      Алматы: "almaty",
      Астана: "astana",
      Атырау: "atirau",
      Есик: "esik",
      Жетысу: "jetisu",
      Караганда: "karaganda",
      Каскелен: "kaskelen",
      Кызылорда: "kizilorda",
      Костанай: "kostanay",
      Нурмакова: "nurmakova",
      Павлодар: "pavlodar",
      Сарыагаш: "sariagash",
      Семей: "semey",
      Шымкент: "shimkent",
      Талдыкорган: "taldikorgan",
      Талгар: "talgar",
      Тараз: "taraz",
      Туркестан: "turkestan",
      Уральск: "uralsk",
      "Усть-Каменогорск": "ustkamenogorsk",
    }[key]),
  marital_status: (key: string) =>
    ({
      married: "Женат/Замужем",
      widowed: "Вдовец/Вдова",
      single: "Холостой/Незамужняя",
      divorced: "Разведён/Разведена",
      civil_marriage: "Гражданский брак",
      "Женат/Замужем": "married",
      "Разведён/Разведена": "divorced",
      "Холостой/Незамужняя": "single",
      "Вдовец/Вдова": "widowed",
      "Гражданский брак": "civil_marriage",
    }[key]),
  gender: (key: string) =>
    ({
      male: "Мужской",
      female: "Женский",
      Мужской: "male",
      Женский: "female",
    }[key]),
  userRole: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case "expert":
          return "Кредитный эксперт";
        case "manager":
          return "Администратор";
        case "root":
          return "Root";
        default:
          return "Кредитный эксперт";
      }
    },
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Кредитный эксперт":
          return "expert";
        case "Администратор":
          return "manager";
        case "Root":
          return "root";
        default:
          return "Кредитный эксперт";
      }
    },
  },
};

export default transcript;
