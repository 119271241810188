import React from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import * as math from "mathjs";
import { Link } from "react-router-dom";

import Input from "components/input";
import Button from "components/button";
import ButtonLoader from "components/button-loader";
import Icon from "icons";
import prettierNumber from "utils/prettier-number";
import transcript from "utils/transcript";
import request from "request";
import modalStyles from "./components/create-modal.module.scss";
import Modal from "./components/create-modal";
import styles from "./home.module.scss";
import home from "./home";

const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);

let debounceTimeout: NodeJS.Timeout;

const Home = () => {
  const [from, setFrom] = React.useState<Date>(firstDayOfYear);
  const [to, setTo] = React.useState<Date>(lastDayOfYear);
  const [page, setPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(1);
  const [newApplicationModal, setNewApplicationModal] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [search, setSearch] = React.useState("");
  const [exportExcelPending, setExportExcelPending] = React.useState(false);
  const resultRef = React.useRef<HTMLDivElement | null>(null);

  const [data, setData] = React.useState<home.result[]>([]);

  const handleFilter = async (FROM?: Date, TO?: Date) => {
    const fd = (FROM ?? from).toLocaleDateString("en-GB").split("/").reverse().join("-");
    const td = (TO ?? to).toLocaleDateString("en-GB").split("/").reverse().join("-");
    setPending(true);
    setPage(1);
    resultRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    request
      .get(`/applications/history?fd=${fd}&td=${td}&page=${page}&client_id=${search}`)
      .then((res) => res.data)
      .then((d) => {
        setData(d.records);
        setRowCount(d.total_count);
      })
      .finally(() => setPending(false));
  };

  const handleClear = () => {
    setFrom(firstDayOfYear);
    setTo(lastDayOfYear);
    handleFilter(firstDayOfYear, lastDayOfYear);
  };

  const handleExportExcel = () => {
    const fd = from.toLocaleDateString("en-GB").split("/").reverse().join("-");
    const td = to.toLocaleDateString("en-GB").split("/").reverse().join("-");
    setExportExcelPending(true);
    request({
      method: "POST",
      url: `/applications/xlsx?fd=${fd}&td=${td}`,
      responseType: "blob"
    })
      .then((res) => res.data)
      .then((res) => {
        const href = URL.createObjectURL(res);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fd + "," + td + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .finally(() => setExportExcelPending(false));
  };

  React.useEffect(() => {
    setPending(true);
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      const handleLoadRows = () => {
        const fd = from.toLocaleDateString("en-GB").split("/").reverse().join("-");
        const td = to.toLocaleDateString("en-GB").split("/").reverse().join("-");
        resultRef.current?.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        request
          .get(`/applications/history?fd=${fd}&td=${td}&page=${page}&client_id=${search}`)
          .then((res) => res.data)
          .then((d) => {
            setData(d.records);
            setRowCount(d.total_count);
          })
          .finally(() => setPending(false));
      };
      handleLoadRows();
    }, 400);
    return () => clearTimeout(debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search]);

  return (
    <div className={styles.results} ref={resultRef}>
      <div className={styles.container}>
        <div className={styles.tools}>
          <div className={styles.filterBox}>
            <div className={styles.input}>
              <Input
                title="Поиск по ID"
                value={search}
                type="string"
                onInput={(value) => setSearch(value)}
              />
            </div>
          </div>
          <div className={styles.filterBox}>
            <div className={styles.input}>
              <Input title="От" value={from} type="date" onSetDate={(d) => setFrom(d)} />
            </div>
            <div className={styles.input}>
              <Input title="До" value={to} type="date" onSetDate={(d) => setTo(d)} />
            </div>
            <div className={styles.buttonGroup}>
              <Button pending={pending} onClick={() => handleFilter()}>
                Фильтр
              </Button>
              <Button pending={pending} color="red" onClick={handleClear}>
                Сбросить
              </Button>
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              pending={exportExcelPending || pending}
              color="orange"
              onClick={handleExportExcel}
            >
              Экспортировать в .xlsx
            </Button>
            <Button color="green" onClick={() => setNewApplicationModal(true)}>
              Новая заявка
            </Button>
          </div>
        </div>
        {pending && (
          <div className={styles.tableLoading}>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
          </div>
        )}
        {!pending && data.length !== 0 && (
          <div className={styles.table}>
            {data.map((item) => {
              const selectedRecommendation = item?.recommendations_log?.find(
                (rec) => rec.id === item?.selected_recommendation
              );
              return (
                <Link
                  to={`application/${item.id}/ocr`}
                  className={styles.tableRowWrap}
                  key={item.id}
                >
                  <div className={styles.tableRow}>
                    <div className={styles.tableRowTop}>
                      {item?.ocr_log && (
                        <div
                          className={classNames(
                            styles.item,
                            item.ocr_log?.is_approved === true && styles.green,
                            item.ocr_log?.is_approved !== null &&
                              item.ocr_log?.is_approved === false &&
                              styles.red
                          )}
                        >
                          <span>{transcript.bankType(item?.ocr_log?.statement_type)}:</span>
                          {item.ocr_log === null || item.ocr_log?.is_approved === null
                            ? "Неизвестно"
                            : item.ocr_log?.is_approved
                            ? "Одобрено"
                            : "Отказано"}
                        </div>
                      )}
                      <div
                        className={classNames(
                          styles.item,
                          item?.pkb_ocr_log?.is_approved === true && styles.green,
                          item?.pkb_ocr_log?.is_approved === false && styles.red
                        )}
                      >
                        <span>ПКБ:</span>
                        {item?.pkb_ocr_log === null
                          ? "Неизвестно"
                          : item?.pkb_ocr_log?.is_approved
                          ? "Одобрено"
                          : "Отказано"}
                      </div>
                      <div
                        className={classNames(
                          styles.item,
                          item.scoring_log?.is_approved === true && styles.green,
                          item.scoring_log?.is_approved === false && item.scoring_log && styles.red
                        )}
                      >
                        <span>Скоринг:</span>
                        {item.scoring_log === null
                          ? "Неизвестно"
                          : item.scoring_log?.is_approved
                          ? "Одобрено"
                          : "Отказано"}
                      </div>
                      <div
                        className={classNames(
                          styles.item,
                          item?.is_approved === true && styles.green,
                          item?.is_approved === false && styles.red
                        )}
                      >
                        <span>Итоговый результат:</span>
                        {item?.is_approved === null
                          ? "Неизвестно"
                          : item?.is_approved
                          ? "Одобрено"
                          : "Отказано"}
                      </div>
                    </div>
                    <div className={styles.tableRowBottom}>
                      <div className={styles.item}>
                        <span>ID</span>
                        <div className={styles.item_value}>{item.user_id}</div>
                      </div>
                      <div className={styles.item}>
                        <span>Эксперт</span>
                        <div className={styles.item_value}>{item?.created_by.email}</div>
                      </div>
                      <div className={styles.item}>
                        <span>Дата создания заявки</span>
                        <div className={styles.item_value}>
                          {new Date(item.created_at).toLocaleString()}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <span>Вид залога</span>
                        <div className={styles.item_value}>
                          {transcript.collateral(item.collateral)}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <span>Сумма</span>
                        <div className={styles.item_value}>
                          {prettierNumber(selectedRecommendation?.amount ?? item.amount)} ₸
                        </div>
                      </div>
                      <div className={styles.item}>
                        <span>Длительность</span>
                        <div className={styles.item_value}>
                          {selectedRecommendation?.duration ?? item.duration} мес.
                        </div>
                      </div>
                      <div className={styles.item}>
                        <span>Процентная ставка</span>
                        <div className={styles.item_value}>{item.interest_rate}%</div>
                      </div>
                      <div className={styles.item}>
                        <span>Вероятность одобрения</span>
                        <div className={styles.item_value}>
                          {item.scoring_log?.prediction
                            ? `${Math.round(
                                (selectedRecommendation?.good_prob ??
                                  item.scoring_log?.prediction) * 100
                              )}%`
                            : "–"}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <span>Порог</span>
                        <div className={styles.item_value}>
                          {item.scoring_log?.threshold
                            ? `${(item.scoring_log?.threshold * 100).toFixed()}%`
                            : "–"}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
        {!pending && data.length === 0 && <div className={styles.tableIsEmptyInfo}>Нет заявок</div>}
        {rowCount > 15 && (
          <div className={styles.pagination}>
            <button
              className={classNames(styles.arrow, page <= 1 && styles.disabled)}
              onClick={() => setPage(math.max(page - 1, 1))}
            >
              <Icon name="arrowLeft" />
            </button>
            <div className={styles.label}>
              {page} / {math.ceil(rowCount / 15)}
            </div>
            <button
              className={classNames(styles.arrow, page * 15 >= rowCount && styles.disabled)}
              onClick={() => setPage(math.min(page + 1, math.ceil(rowCount / 15)))}
            >
              <Icon name="arrowRight" />
            </button>
          </div>
        )}
      </div>
      <CSSTransition
        in={!!newApplicationModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive
        }}
      >
        <Modal onClose={() => setNewApplicationModal(false)} />
      </CSSTransition>
    </div>
  );
};

export default Home;
