import React from "react";
import { useNavigate } from "react-router-dom";

import request from "request";
import Button from "components/button";
import Input from "components/input";
import transcript from "utils/transcript";
import styles from "./create-modal.module.scss";

const Modal = ({ onClose }: { onClose: () => void }) => {
  const [userId, setUserId] = React.useState("");
  const [sum, setSum] = React.useState("");
  const [interestRate, setInterestRate] = React.useState("");
  const [duration, setDuration] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [collateral, setCollateral] = React.useState("");
  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name:
      | "userId"
      | "paymentMethod"
      | "collateral"
      | "sum"
      | "interestRate"
      | "duration";
    value: string;
  } | null>(null);

  const navigate = useNavigate();

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userId.trim().length === 0) {
      return setValidError({
        name: "userId",
        value: "ID клиента обязательно",
      });
    } else if (collateral.trim().length === 0) {
      return setValidError({
        name: "collateral",
        value: "Вид залога обязателен",
      });
    } else if (sum.trim().length === 0) {
      return setValidError({
        name: "sum",
        value: "Сумма обязательна",
      });
    } else if (paymentMethod.trim().length === 0) {
      return setValidError({
        name: "paymentMethod",
        value: "Метод погашения обязателен",
      });
    } else if (interestRate.trim().length === 0) {
      return setValidError({
        name: "interestRate",
        value: "Процентная ставка обязательна",
      });
    } else if (duration.trim().length === 0) {
      return setValidError({
        name: "duration",
        value: "Длительность кредита обязательна",
      });
    } else setValidError(null);
    setPending(true);
    request
      .post("/applications", {
        user_id: userId,
        amount: sum,
        collateral: transcript.collateral(collateral),
        interest_rate: interestRate,
        payment_method: transcript.paymentMethod(paymentMethod),
        duration,
      })
      .then((res) => res.data)
      .then((data) => {
        navigate(`application/${data.id}/ocr`);
      })
      .finally(() => setPending(false));
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
        onSubmit={handleCreate}
      >
        <div className={styles.title}>Новая заявка</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="ID клиента"
              type="string"
              value={userId}
              onInput={(value) => setUserId(value)}
            />
            {validError?.name === "userId" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Вид залога"
              id="collateral"
              value={collateral}
              type="select"
              onSelect={(option) => {
                setCollateral(option);
                setSum("");
                setDuration("");
              }}
              options={["Автомобиль", "Недвижимость", "Без залога"]}
            />
            {validError?.name === "collateral" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Сумма"
              type="integer"
              value={sum}
              onInput={(value) => setSum(value)}
              step={1000}
              min={collateral === "Без залога" ? 33601 : 66117}
              max={55000000}
              placeholder={`мин: ${
                collateral === "Без залога" ? 33601 : 66117
              }  макс: 55000000`}
              unit="₸"
            />
            {validError?.name === "sum" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Процентная ставка"
              type="float"
              value={interestRate}
              onInput={(value) => setInterestRate(value)}
              step={0.1}
              min={35}
              max={47}
              placeholder="мин: 35  макс: 47"
              unit="%"
            />
            {validError?.name === "interestRate" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Длительность"
              type="float"
              value={duration}
              onInput={(value) => setDuration(value)}
              step={1}
              min={1}
              max={collateral === "Без залога" ? 36 : 120}
              placeholder={`мин: 1  макс: ${
                collateral === "Без залога" ? 36 : 120
              }`}
              unit="мес."
            />
            {validError?.name === "duration" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Метод погашения"
              id="paymentMethod"
              value={paymentMethod}
              type="select"
              onSelect={(option) => setPaymentMethod(option)}
              options={["Аннуитетный", "Процентный"]}
            />
            {validError?.name === "collateral" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Отмена
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Создать
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Modal;
