import { AxiosError } from "axios";
import Button from "components/button";
import Input from "components/input";
import Loading from "components/loading";
import ResultBox from "components/result-box";
import global from "global";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import prettierNumber from "utils/prettier-number";
import transcript from "utils/transcript";
import Modal from "./modals/recommendation";
import modalStyles from "./modals/recommendation.module.scss";
import styles from "./scoring.module.scss";

const initialFormState = {
  // cb_score: "",
  income_amount: "",
  occupation_area: "",
  occupation: "",
  market_cost: "",
  liquid_cost: "",
  ltv: "",
  co_borrower: "",
  age: "",
  gender: "",
  marital_status: "",
  branch: "",
  dependants: "",
  work_experience: "",
  has_car: "",
  has_house: "",
  type_of_prev_credits: "",
};

const Scoring = () => {
  const { id } = useParams();
  const contextApplication = useSelector((state) => state.application);
  const user = useSelector((state) => state.user);
  const { first_name, last_name, email } =
    contextApplication.data?.created_by ?? {};
  const dispatch = useDispatch();

  const [pending, setPending] = React.useState(false);
  const [shapPending, setShapLoading] = React.useState(false);
  const [validationCheck, setValidationCheck] = React.useState(false);
  const [recommendationModal, setRecommendationModal] = React.useState(false);
  const [recommendatedAmount, setRecommendatedAmount] =
    React.useState<number>();

  const [macrodata, setMacrodata] = React.useState<global.macrodata>();
  const [form, setForm] = React.useState(initialFormState);

  const handleCalculate = () => {
    let timeout: NodeJS.Timeout;
    setPending(true);
    request
      .post(`/applications/${id}/scoring`, {
        // cb_score: Number(form.cb_score),
        income_amount: Number(form.income_amount),
        occupation_area: transcript.occupation_area(form.occupation_area),
        occupation: transcript.occupation(form.occupation),
        market_cost: Number(form.market_cost),
        liquid_cost: Number(form.liquid_cost),
        ltv: Number(form.ltv),
        co_borrower: form.co_borrower === "Есть",
        age: Number(form.age),
        gender: transcript.gender(form.gender),
        marital_status: transcript.marital_status(form.marital_status),
        branch: transcript.branch(form.branch),
        dependants: Number(form.dependants),
        work_experience: transcript.work_experience(form.work_experience),
        has_car: form.has_car === "Есть",
        has_house: form.has_house === "Есть",
        type_of_prev_credits: Number(form.type_of_prev_credits),
      })
      .then((res) => res.data)
      .then(() => {
        timeout = setTimeout(async () => {
          if (
            contextApplication.data?.pkb_ocr_log &&
            contextApplication.data?.ocr_log
          )
            await request.post(
              `applications/${contextApplication.data.id}/cdn`
            );
          dispatch.application.FETCH_APPLICATION_BY_ID(id);
        }, 1000);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 409) {
          let textAmount = ((e.response.data as any).detail as string).split(
            "is"
          )[1];
          setRecommendationModal(true);
          setRecommendatedAmount(
            Number(textAmount.slice(0, textAmount.length - 1))
          );
        } else if (e.response?.status === 418)
          toast.error(
            `Для данной суммы, ЛТВ по ${transcript.collateral(
              contextApplication?.data?.collateral!
            )} превышает. Рекомендуемая сумма отсутсвует.`
          );
      })
      .finally(() => setPending(false));
    return () => clearTimeout(timeout);
  };

  React.useEffect(() => {
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d));
  }, []);

  React.useEffect(() => {
    setValidationCheck(
      form.age !== "" &&
        form.branch !== "" &&
        // form.cb_score !== "" &&
        form.co_borrower !== "" &&
        form.dependants !== "" &&
        form.gender !== "" &&
        form.income_amount !== "" &&
        form.marital_status !== "" &&
        form.occupation !== "" &&
        form.occupation_area !== "" &&
        form.type_of_prev_credits !== "" &&
        form.work_experience !== "" &&
        form.has_car !== "" &&
        form.has_house !== "" &&
        (form.ltv !== "" || contextApplication.data?.collateral === "none") &&
        (form.liquid_cost !== "" ||
          contextApplication.data?.collateral === "none") &&
        (form.market_cost !== "" ||
          contextApplication.data?.collateral === "none")
    );
  }, [
    contextApplication.data?.collateral,
    form.age,
    form.branch,
    // form.cb_score,
    form.co_borrower,
    form.dependants,
    form.gender,
    form.has_car,
    form.has_house,
    form.income_amount,
    form.liquid_cost,
    form.ltv,
    form.marital_status,
    form.market_cost,
    form.occupation,
    form.occupation_area,
    form.type_of_prev_credits,
    form.work_experience,
  ]);

  return (
    <form className={styles.scoring}>
      <div className={styles.fields}>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Данные кредита</div>
          {contextApplication.data?.scoring_log && (
            <div className={styles.description}>
              <div>
                Скоринг проведён экспертом:{" "}
                {[first_name, last_name, "(" + email + ")"].join(" ")}
              </div>
              <div>
                Дата проведения скоринга:{" "}
                {new Date(
                  contextApplication.data?.scoring_log.created_at
                ).toLocaleString()}
              </div>
            </div>
          )}
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>ID клиента</div>
              <div className={styles.value}>
                {contextApplication.data?.user_id}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Вид залога</div>
              <div className={styles.value}>
                {transcript.collateral(
                  contextApplication.data?.collateral ?? ""
                )}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Сумма</div>
              <div className={styles.value}>
                {prettierNumber(contextApplication.data?.amount ?? 0)} ₸
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Длительность</div>
              <div className={styles.value}>
                {contextApplication.data?.duration} мес.
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Процентная ставка</div>
              <div className={styles.value}>
                {contextApplication.data?.interest_rate} %
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Метод погашения</div>
              <div className={styles.value}>
                {transcript.paymentMethod(
                  contextApplication.data?.payment_method ?? ""
                )}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Порог</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.threshold
                  ? (
                      contextApplication.data?.scoring_log?.threshold * 100
                    ).toFixed()
                  : (
                      ((contextApplication.data?.collateral === "none"
                        ? macrodata?.threshold_without_collateral
                        : macrodata?.threshold_with_collateral) ?? 0) * 100
                    ).toFixed()}{" "}
                %
              </div>
            </div>
            {contextApplication.data?.scoring_log && (
              <>
                {/* <div className={styles.item}>
                  <div className={styles.key}>Рейтинг клиента в БКИ</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.cb_score}
                  </div>
                </div> */}
                <div className={styles.item}>
                  <div className={styles.key}>Филиал</div>
                  <div className={styles.value}>
                    {transcript.branch(
                      contextApplication.data.scoring_log.branch
                    )}
                  </div>
                </div>
                {contextApplication.data.collateral !== "none" && (
                  <>
                    <div className={styles.item}>
                      <div className={styles.key}>LTV</div>
                      <div className={styles.value}>
                        {contextApplication.data.scoring_log.ltv}
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Рыночная стоимость</div>
                      <div className={styles.value}>
                        {contextApplication.data.scoring_log.market_cost}
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Ликвидная стоимость</div>
                      <div className={styles.value}>
                        {contextApplication.data.scoring_log.liquid_cost}
                      </div>
                    </div>
                  </>
                )}
                <div className={styles.item}>
                  <div className={styles.key}>Ежемесячный доход клиента</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.income_amount}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Пол</div>
                  <div className={styles.value}>
                    {transcript.gender(
                      contextApplication.data.scoring_log.gender
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Возраст</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.age}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Семейное положение</div>
                  <div className={styles.value}>
                    {transcript.marital_status(
                      contextApplication.data.scoring_log.marital_status
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Цикл клиента</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.type_of_prev_credits}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Количество иждивенцев</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.dependants}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Опыт</div>
                  <div className={styles.value}>
                    {transcript.work_experience(
                      contextApplication.data.scoring_log.work_experience
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Статус деятельности</div>
                  <div className={styles.value}>
                    {transcript.occupation(
                      contextApplication.data.scoring_log.occupation
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Род деятельности</div>
                  <div className={styles.value}>
                    {transcript.occupation_area(
                      contextApplication.data.scoring_log.occupation_area
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Наличие машины</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.has_car
                      ? "Есть"
                      : "Нет"}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Наличие дома</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.has_house
                      ? "Есть"
                      : "Нет"}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Поручитель/Созаемщик</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.co_borrower
                      ? "Есть"
                      : "Нет"}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Макроданные</div>{" "}
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>Инфляция прод.товаров</div>
              <div className={styles.value}>{macrodata?.food_inflation}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Инфляция непрод.товаров</div>
              <div className={styles.value}>{macrodata?.nonfood_inflation}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Инфляция услуг</div>
              <div className={styles.value}>
                {macrodata?.services_inflation}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Курс доллара</div>
              <div className={styles.value}>{macrodata?.exrate_usd_avg}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Средний доход населения</div>
              <div className={styles.value}>{macrodata?.avrate}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>ВВП</div>
              <div className={styles.value}>{macrodata?.gdp}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Цена на нефть Brent</div>
              <div className={styles.value}>{macrodata?.brent_oil_price}</div>
            </div>
          </div>
        </div>
        {user.role !== "expert" && contextApplication.data?.scoring_log && (
          <div className={styles.shap}>
            {shapPending && <Loading />}
            <div className={styles.title}>Интерпретация решения</div>
            <iframe
              title="shap_link"
              src={contextApplication.data?.scoring_log.shap_link}
              style={{ width: "100%" }}
              onLoad={() => setShapLoading(false)}
            ></iframe>
          </div>
        )}
        {!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.fieldGroup}>
              <div className={styles.fieldList}>
                {/* <Input
                  title="Рейтинг клиента в БКИ"
                  value={form.cb_score}
                  type="integer"
                  step={1}
                  min={0}
                  max={1000}
                  placeholder="мин: 0  макс: 1000"
                  onInput={(value) => setForm({ ...form, cb_score: value })}
                /> */}
                <Input
                  title="Филиал"
                  id="branch"
                  value={form.branch}
                  type="select"
                  hasFilterText
                  onSelect={(option) => setForm({ ...form, branch: option })}
                  options={[
                    "Актау",
                    "Актобе",
                    "Алматы",
                    "Астана",
                    "Атырау",
                    "Есик",
                    "Жетысу",
                    "Караганда",
                    "Каскелен",
                    "Кызылорда",
                    "Костанай",
                    "Нурмакова",
                    "Павлодар",
                    "Сарыагаш",
                    "Семей",
                    "Шымкент",
                    "Талдыкорган",
                    "Талгар",
                    "Тараз",
                    "Туркестан",
                    "Уральск",
                    "Усть-Каменогорск",
                  ]}
                />
                {contextApplication.data?.collateral !== "none" && (
                  <>
                    <Input
                      title="LTV"
                      value={form.ltv}
                      type="integer"
                      step={1}
                      min={20}
                      max={80}
                      placeholder="мин: 20  макс: 80"
                      onInput={(value) => setForm({ ...form, ltv: value })}
                    />
                    <Input
                      title="Рыночная стоимость"
                      value={form.market_cost}
                      type="integer"
                      step={100}
                      min={0}
                      placeholder="мин: 0"
                      onInput={(value) =>
                        setForm({ ...form, market_cost: value })
                      }
                    />
                    <Input
                      title="Ликвидная стоимость"
                      value={form.liquid_cost}
                      type="integer"
                      step={100}
                      min={0}
                      placeholder="мин: 0"
                      onInput={(value) =>
                        setForm({ ...form, liquid_cost: value })
                      }
                    />
                  </>
                )}
                <Input
                  title="Ежемесячный доход клиента"
                  value={form.income_amount}
                  type="integer"
                  step={100}
                  min={
                    contextApplication.data?.collateral === "none"
                      ? 26000
                      : 37000
                  }
                  max={5000000}
                  placeholder={`мин: ${
                    contextApplication.data?.collateral === "none"
                      ? 26000
                      : 37000
                  }  макс: 5000000`}
                  onInput={(value) =>
                    setForm({ ...form, income_amount: value })
                  }
                />
                <Input
                  title="Пол"
                  id="gender"
                  value={form.gender}
                  type="select"
                  onSelect={(option) => setForm({ ...form, gender: option })}
                  options={["Мужской", "Женский"]}
                />
                <Input
                  title="Возраст"
                  value={form.age}
                  type="integer"
                  step={1}
                  min={19}
                  max={contextApplication.data?.collateral === "none" ? 79 : 78}
                  placeholder={`мин: 19  макс: ${
                    contextApplication.data?.collateral === "none" ? 79 : 78
                  }`}
                  onInput={(value) => setForm({ ...form, age: value })}
                />
                <Input
                  title="Семейное положение"
                  id="marital_status"
                  value={form.marital_status}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, marital_status: option })
                  }
                  options={[
                    "Женат/Замужем",
                    "Разведён/Разведена",
                    "Холостой/Незамужняя",
                    "Вдовец/Вдова",
                    "Гражданский брак",
                  ]}
                />
                <Input
                  title="Цикл клиента"
                  value={form.type_of_prev_credits}
                  type="integer"
                  step={1}
                  min={0}
                  max={5}
                  placeholder="мин: 0  макс: 5"
                  onInput={(value) =>
                    setForm({ ...form, type_of_prev_credits: value })
                  }
                />
                <Input
                  title="Количество иждивенцев"
                  value={form.dependants}
                  type="integer"
                  step={1}
                  min={0}
                  max={10}
                  placeholder="мин: 0  макс: 10"
                  onInput={(value) => setForm({ ...form, dependants: value })}
                />
                <Input
                  title="Опыт"
                  id="work_experience"
                  value={form.work_experience}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, work_experience: option })
                  }
                  options={[
                    "Без опыта",
                    "Менее 1 года",
                    "От 1 до 3 лет",
                    "От 3 до 5 лет",
                    "Более 5 лет",
                  ]}
                />
                <Input
                  title="Статус деятельности"
                  id="occupation"
                  value={form.occupation}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, occupation: option })
                  }
                  options={[
                    "Рабочий",
                    "Пенсионер",
                    "Специалист",
                    "Руководитель",
                    "Предприниматель",
                  ]}
                />
                <Input
                  title="Род деятельности"
                  id="occupation_area"
                  hasFilterText
                  value={form.occupation_area}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, occupation_area: option })
                  }
                  options={[
                    "Банки и финансы",
                    "Образование",
                    "ЖКХ",
                    "Медицина",
                    "Сельское хозяйство",
                    "Строительство",
                    "Пенсионер",
                    "Полиция",
                    "Производство",
                    "Торговля",
                    "Транспорт",
                    "Госслужащий",
                    "Услуги",
                    "Другое",
                  ]}
                />
                <Input
                  title="Наличие машины"
                  id="has_car"
                  value={form.has_car}
                  type="select"
                  onSelect={(option) => setForm({ ...form, has_car: option })}
                  options={["Есть", "Нет"]}
                />
                <Input
                  title="Наличие дома"
                  id="has_house"
                  value={form.has_house}
                  type="select"
                  onSelect={(option) => setForm({ ...form, has_house: option })}
                  options={["Есть", "Нет"]}
                />
                <Input
                  title="Поручитель/Созаемщик"
                  id="co_borrower"
                  value={form.co_borrower}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, co_borrower: option })
                  }
                  options={["Есть", "Нет"]}
                />
              </div>
            </div>
            {validationCheck && (
              <div className={styles.footerBox}>
                <div className={styles.buttonGroup}>
                  <Button pending={pending} onClick={handleCalculate}>
                    Запустить скоринг
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.result}>
        {pending && (
          <div className={styles.resultBox}>
            <Loading size={50} />
          </div>
        )}
        <ResultBox />
      </div>
      <CSSTransition
        in={!!recommendationModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <Modal
          application={contextApplication.data}
          recommendateAmount={recommendatedAmount ?? 0}
          onClose={() => setRecommendationModal(false)}
        />
      </CSSTransition>
    </form>
  );
};

export default Scoring;
